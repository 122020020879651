import React from "react"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { getCurrentLangKey } from "ptz-i18n"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Books = props => {
  const { data } = props
  const { basicPythonCover } = data
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Meus livros" />
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="md:w-12/12 mb-12">
              <h1 className="mb-4 font-bold text-3xl no-underline">
                Meus livros
              </h1>
              <p>
                Estes são os livros que escrevi. Se quiser fazer o download
                deles, pode comprá-los e pagar o quanto achar justo. Ou você
                pode ler os livros online sem se preocupar com nada disso.
              </p>
            </div>
          </div>

          <div className="w-full">
            <Link to="/pt/books/basic-python">
              <h2 className="mt-4 mb-4 font-bold text-blue-500 text-xl">
                Python Básico
              </h2>
            </Link>
          </div>
          <div className="w-full flex items-center">
            <div className="md:w-1/12 inline-block">
              <Link to="/pt/books/basic-python">
                <Img
                  fluid={basicPythonCover.childImageSharp.fluid}
                  alt="Felipe Galvão"
                  className="shadow-md"
                />
              </Link>
            </div>
            <div className="md:w-11/12 pl-8 inline-block">
              <p>
                Esse livro ensina o básico do Python. Estruturas de dados,
                operações básicas, algumas das bibliotecas principais para lidar
                com situações do dia a dia, como datas, por exemplo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    basicPythonCover: file(absolutePath: { regex: "/capa-livro-python.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Books
